import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "./../contactForm/ContactFormContainer";

const ProductsView = ({ categories }) => (
  <div>
       <div
      style={{
        backgroundImage: "url(./src/resources/images/productsBanner.jpg)",
        borderBottom: '1px solid #000000'
      }}
      className="bannerImage"
    />

    <div className="block">
      <h2>Våra produkter</h2>
      <div className="blockContent">
        <p>Under dessa produktkategorier hittar ni en liten del av vårt sortiment. Om det ni söker inte finns på hemsidan, kontakta oss gärna så hjälper vi er vidare.</p>
        <div className="blockContent flex">
          {categories.map(cat => {
            return (
              <Link key={cat.id} id={cat.id} to={"/category" + cat.id.replace(/\./g, '_')}
                  className="prodNav"
                  style={{
                    backgroundImage: "url(./src/resources/categories/Cat_"+ cat.id +".jpg)"
                  }}
                >
                  <div className="bannerText">{cat.namn}</div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>

    <ContactForm />
  </div>
);

export default ProductsView;
