import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ContactForm from "./../contactForm/ContactFormContainer";

const StartPage = ({ categories }) => (
  <div className="startPage">
    <div
      style={{
        backgroundImage: "url(./src/resources/images/industriBanner.jpg)"
      }}
      className="bannerImage"
    />

    <div className="block">
      <div className="blockContent">
        <h1>Välkommen till LH-produkter</h1>
        LH-Produkter är ett företag i Linköping som tillverkar och säljer lyft-
        och surrningsredskap. Egen tillverkning av stållinestroppar,
        kättingredskap, syntetiska lyftredskap, samt egen sömnad av surrningar.
        <br />
        <br />
        Återförsäljare för de större tillverkarna av lyftredskap.
      </div>
    </div>

    <div className="block--gray">
      <h2>Våra produkter</h2>
      <i className="blockIcon fas fa-link"></i>
      <div className="blockContent flex">

        {categories.map(cat => {
          return (
            <Link key={cat.id} id={cat.id} to={"/category" + cat.id.replace(/\./g, '_')}
                className="prodNav"
                style={{
                  backgroundImage: "url(./src/resources/categories/Cat_"+ cat.id +".jpg)"
                }}
              >
                <div className="bannerText">{cat.namn}</div>

            </Link>
          );
        })}
      </div>
    </div>

    <div className="block about">
      <i className="blockIcon fas fa-info-circle"></i>
      <h2>Om oss på LH-produkter</h2>
      <div className="blockContent">
        <p>LH-produkter är ett företag som bildades 1969 i Valskog utanför Eskilstuna. Numera finns
        vi i Linköping.</p>

        <p>Vi tillverkar och säljer lyft- och surrningsredskap. 
          Egen tillverkning av stållinestroppar,
          kättingredskap, syntetiska lyftredskap, samt egen sömnad av surrningar efter kundens önskemål och behov. 
          Återförsäljare för de större tillverkarna av olika lyftredskap.</p>
      </div>
    </div>

    <ContactForm />
  </div>
);

StartPage.propTypes = {
  categories: PropTypes.array
};

export default StartPage;
