import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import StartPageContainer from "./startpage/StartPageContainer";
import CategoryContainer from "./category/CategoryContainer";
import ProductsContainer from "./products/ProductsContainer";
import ProductsViewContainer from "./products/ProductsViewContainer";
import InfoContainer from "./about/AboutView";
import CasesView from "./cases/casesView";
import ContactView from "./contact/contactView";
import Header from "./common/Header";
import Menu from "./menu/menu";

import {action as toggleMenu} from 'redux-burger-menu';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state =  {
      menuOpen: false
    }
  }

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  handleMenuClick() {
    const isOpen = false;
    this.props.toggleMenu(isOpen);
  }

  render() {
    return (
      <Router>
        <div id="outer-container">
          <Menu customBurgerIcon={false} isOpen={ this.props.menuOpen } right disableOverlayClick={false} pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" } >
            <Link className="menu-item one" onClick={ this.handleMenuClick.bind(this) } to={"/"}>Startsidan</Link>
            <Link className="menu-item two" onClick={ this.handleMenuClick.bind(this) } to={"/ourproducts"}>Våra produkter</Link>
            <Link className="menu-item three" onClick={ this.handleMenuClick.bind(this) } to={"/cases"}>Våra kundområden</Link>
            <Link className="menu-item four" onClick={ this.handleMenuClick.bind(this) } to={"/about"}>Om LH-produkter</Link>
            <Link className="menu-item five" onClick={ this.handleMenuClick.bind(this) } to={"/contact"}>Kontakta oss</Link>
          </Menu>
          <Header />
          <div className="container">
            <Switch>
              <Route exact path="/" component={StartPageContainer} />
              <Route exact path="/category:Id" component={CategoryContainer} />
              <Route exact path="/products:Id" component={ProductsContainer} />
              <Route exact path="/ourproducts" component={ProductsViewContainer} />
              <Route exact path="/contact" component={ContactView} />
              <Route exact path="/about" component={InfoContainer} />
              <Route exact path="/cases" component={CasesView} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

App.propTypes = {
  menuOpen: PropTypes.bool,
  toggleMenu: PropTypes.func
};

function mapStateToProps(state) {
  return {
    menuOpen: state.burgerMenu.isOpen
  }  
}

const mapDispatchToProps = {
  toggleMenu: toggleMenu
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);