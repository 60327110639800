import React from "react";
import ContactForm from "./../contactForm/ContactFormContainer";

const AboutPage = () => (
  <div>
       <div
      style={{
        backgroundImage: "url(./src/resources/images/aboutBanner.jpg)",
        borderBottom: '1px solid #000000'
      }}
      className="bannerImage"
    />

    <div className="block">
      <h2>Om oss på LH-produkter</h2>
      <div className="blockContent">
        <p>LH-produkter är ett företag som bildades 1969 i Valskog utanför Eskilstuna. Numera finns
        vi i Linköping.</p>

        <p>Vi tillverkar och säljer lyft- och surrningsredskap. 
          Egen tillverkning av stållinestroppar,
          kättingredskap, syntetiska lyftredskap, samt egen sömnad av surrningar efter kundens önskemål och behov. 
          Återförsäljare för de större tillverkarna av olika lyftredskap.</p>

        

        <br />
        <hr/>
        <br />
        <h2>Hitta till oss</h2>
        <strong>Våran besöksadress är:</strong><br /> Nyckelgatan 13, 58941 Linköping<br /><br />
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2090.7545761088472!2d15.700004016125902!3d58.397538192420015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46596ea0dd19b53d%3A0x26537c7179dfaf67!2sLH-Produkter%20i%20Link%C3%B6ping%20AB!5e0!3m2!1ssv!2sse!4v1598901604430!5m2!1ssv!2sse"
          className="iframeGoogleMaps"></iframe>
      </div>
    </div>

    <ContactForm />
  </div>
);

export default AboutPage;
