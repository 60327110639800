import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Category from "./Category";
import allData from "./../../data/allData";

class CategoryContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state =  {
      category: null,
      subCategories: [],
      parentCategories: [],
      slug: null
    }
  }

  updateData() {
    const slug = this.props.match.params.Id;
    let category = allData.categories.find(item => item.id === slug.replace(/_/g, '.'));

    let subCategories = allData.categories.filter(item => {
      return (item.id.startsWith(slug.replace(/_/g, '.') + ".") && (item.id.length <= (slug.length + 3)))
    });

    subCategories.map(subCat => {
      if(allData.products.find(p => p.categoryId === subCat.id)) {
        subCat.hasProd = true;
      }
    });

    let parentCategories = this.getParentCat(category, []);

    this.setState({parentCategories, subCategories, category, slug});
  }

  getParentCat = function(cat, arr) {
    let parent;

    while (cat && cat.parent) {
      parent = allData.categories.find(item => {
        return (item.id == cat.parent);
      });

      if(parent) {
        arr.unshift(parent);
      } 

      cat = (parent && parent.parent) ? parent : {};
    }

    return arr;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateData();
  }

  componentDidUpdate() {
    let _slug = this.props.match.params.Id;
    if(this.state.slug != _slug) {
      this.updateData();
    }
  }

  render() {
    return (
      <Category
        parentCategories={this.state.parentCategories}
        subCategories={this.state.subCategories}
        category={this.state.category} />
    );
  }
}

CategoryContainer.propTypes = {
  match: PropTypes.object,
};

function mapStateToProps() {
  return {}  
}

const mapDispatchToProps = {

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryContainer);