import React from "react";
import { connect } from "react-redux";
import ProductsView from "./ProductsView";
import allData from "./../../data/allData";

class ProductsViewContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state =  {
      categories: []
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let categories = allData.categories.filter(item => {
      return !(item.id.includes("."))
    });
    this.setState({categories});
    console.log("Categories: ",categories);

  }

  render() {
    return (
      <ProductsView
        categories={this.state.categories} />
    );
  }
}

ProductsViewContainer.propTypes = {};

function mapStateToProps() {
  return {}  
}

const mapDispatchToProps = {

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsViewContainer);