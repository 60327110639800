import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {action as toggleMenu} from 'redux-burger-menu';
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state =  {
      path: '',
      searchShow: false
    }

    this.searchInputRef = React.createRef();
  }

  openMenu() {
    const isOpen = true
    this.props.toggleMenu(isOpen);
  }

  toggleSearch() {
    this.setState({ searchShow: (this.state.searchShow) ? false : true });
    this.searchInputRef.current.focus()
  }

  menuChange(e) {
    this.setState({path: 1});
  }

  render() {
    return (
      <div className="header">
        <img src="./src/resources/images/logo_50.png" className="header__logo" />
        <img src="./src/resources/images/hamburger_inactive.svg" className="header__menuTrigger" onClick={this.openMenu.bind(this)} />
        <img src="./src/resources/images/search.svg" className="header__searchTrigger" onClick={this.toggleSearch.bind(this)} />

        <div className={  (this.state.searchShow) ? "searchFormContainer show" : "searchFormContainer"}>
          <input type="text" value="" ref={this.searchInputRef} placeholder="Vad söker du efter?" />
        </div>

        <div className="header-menu">
          <NavLink exact activeClassName="selected" to="/">Startsidan</NavLink>
          <NavLink exact activeClassName="selected" to="/ourproducts">Våra produkter</NavLink>
          <NavLink exact activeClassName="selected" to="/cases">Våra kundområden</NavLink>
          <NavLink exact activeClassName="selected" to="/about">Om LH-produkter</NavLink>
          <NavLink exact activeClassName="selected" to="/contact">Kontakta oss</NavLink>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  toggleMenu: PropTypes.func.isRequired
};

function mapStateToProps() {
  return {}  
}

const mapDispatchToProps = {
  toggleMenu: toggleMenu
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
