export default {
  categories: [
    {
      id: "1",
      namn: "Stållinor",
      beskrivning:
        "LH-produkter erbjuder ett brett sortiment av stållinor från kända varumärken. Vi levererar i  många dimensioner och typer, allt ifrån enkla standardlinor till avancerade rotationsfattiga stållinor, både förzinkat och rostfritt. Stållinestropparna anpassas och levereras i längd och utförande helt efter kundens önskemål.",
    },
    {
      id: "1.1",
      parent: "1",
      namn: "Galvad stållina",
      beskrivning: "",
    },
    {
      id: "1.1.1",
      parent: "1.1",
      namn: "Stållina med fiberkärna",
      beskrivning: "",
    },
    {
      id: "1.1.2",
      parent: "1.1",
      namn: "Stållina med stålkärna",
      beskrivning: "",
    },
    {
      id: "1.1.3",
      parent: "1.1",
      namn: "Rotationsfattig stållina",
      beskrivning: "",
    },
    {
      id: "1.1.4",
      parent: "1.1",
      namn: "Svart stållina",
      beskrivning: "",
    },
    {
      id: "1.2",
      parent: "1",
      namn: "Rostfri stållina",
      beskrivning: "",
    },
    {
      id: "1.3",
      parent: "1",
      namn: "Plastad stållina",
      beskrivning: "",
    },
    {
      id: "1.3.1",
      parent: "1.3",
      namn: "Galvad stållina",
      beskrivning: "",
    },
    {
      id: "1.3.2",
      parent: "1.3",
      namn: "Rostfri stållina",
      beskrivning: "",
    },
    {
      id: "1.4",
      parent: "1",
      namn: "Tillbehör stållina",
      beskrivning: "",
    },
    {
      id: "1.4.1",
      parent: "1.4",
      namn: "Stållinelås",
      beskrivning: "",
    },
    {
      id: "1.4.1.1",
      parent: "1.4.1",
      namn: "Bygellås",
      beskrivning: "",
    },
    {
      id: "1.4.1.2",
      parent: "1.4.1",
      namn: "Låsbleck Duplex",
      beskrivning: "",
    },
    {
      id: "1.4.1.3",
      parent: "1.4.1",
      namn: "Backlås",
      beskrivning: "",
    },
    {
      id: "1.4.1.4",
      parent: "1.4.1",
      namn: "Presslås",
      beskrivning: "",
    },
    {
      id: "1.4.2",
      parent: "1.4",
      namn: "Kaus",
      beskrivning: "",
    },
    {
      id: "1.4.3",
      parent: "1.4",
      namn: "Vantskruv",
      beskrivning: "",
    },
    {
      id: "1.4.4",
      parent: "1.4",
      namn: "Kastblock",
      beskrivning: "",
    },
    {
      id: "1.5",
      parent: "1",
      namn: "Lyftredskap",
      beskrivning: "",
    },
    {
      id: "1.6",
      parent: "1",
      namn: "Stålinerundsling",
      beskrivning: "",
    },
    {
      id: "2",
      namn: "Kätting",
      beskrivning:
        "LH-produkter erbjuder ett brett sortiment av kätting och kättingredskap. Vi levererar i  olika dimensioner, klasser och maxlaster,  i både varmförzinkat och rostfritt . Kättingredskapen anpassas och levereras i längd och utförande helt efter kundens önskemål.",
    },
    {
      id: "2.1",
      parent: "2",
      namn: "Klass 8",
      beskrivning: "",
    },
    {
      id: "2.1.1",
      parent: "2.1",
      namn: "Redskap",
      beskrivning: "",
    },
    {
      id: "2.1.2",
      parent: "2.1",
      namn: "Kätting",
      beskrivning: "",
    },
    {
      id: "2.1.3",
      parent: "2.1",
      namn: "Komponenter",
      beskrivning: "",
    },
    {
      id: "2.2",
      parent: "2",
      namn: "Klass 10",
      beskrivning: "",
    },
    {
      id: "2.2.1",
      parent: "2.2",
      namn: "Redskap",
      beskrivning: "",
    },
    {
      id: "2.2.2",
      parent: "2.2",
      namn: "Kätting",
      beskrivning: "",
    },
    {
      id: "2.2.3",
      parent: "2.2",
      namn: "Komponenter",
      beskrivning: "",
    },
    {
      id: "2.3",
      parent: "2",
      namn: "Handelskätting samt komponenter",
      beskrivning: "",
    },
    {
      id: "2.3.1",
      parent: "2.3",
      namn: "Kätting",
      beskrivning: "",
    },
    {
      id: "2.3.1.1",
      parent: "2.3.1",
      namn: "Galvad klass 3",
      beskrivning: "",
    },
    {
      id: "2.3.1.2",
      parent: "2.3.1",
      namn: "Rostfri",
      beskrivning: "",
    },
    {
      id: "2.3.3",
      parent: "2.3",
      namn: "Schackel",
      beskrivning: "",
    },
    {
      id: "2.3.3.1",
      parent: "2.3.3",
      namn: "Galvad klass 3",
      beskrivning: "",
    },
    {
      id: "2.3.3.2",
      parent: "2.3.3",
      namn: "Rostfri",
      beskrivning: "",
    },
    {
      id: "2.3.4",
      parent: "2.3",
      namn: "Maskinlyftögla",
      beskrivning: "",
    },
    {
      id: "2.3.4.1",
      parent: "2.3.4",
      namn: "Galvad",
      beskrivning: "",
    },
    {
      id: "2.3.4.2",
      parent: "2.3.4",
      namn: "Rostfri",
      beskrivning: "",
    },
    {
      id: "2.3.5",
      parent: "2.3",
      namn: "Karbinhake",
      beskrivning: "",
    },
    {
      id: "2.4",
      parent: "2",
      namn: "Plastkätting",
      beskrivning: "",
    },
    {
      id: "3",
      namn: "Mjuka lyft",
      beskrivning: "",
    },
    {
      id: "3.1",
      parent: "3",
      namn: "Rundsling",
      beskrivning: "",
    },
    {
      id: "3.2",
      parent: "3",
      namn: "Stållinerundsling",
      beskrivning: "",
    },
    {
      id: "3.3",
      parent: "3",
      namn: "Bandsling",
      beskrivning: "",
    },
    {
      id: "3.4",
      parent: "3",
      namn: "Rundslingsstropp",
      beskrivning: "",
    },
    {
      id: "3.5",
      parent: "3",
      namn: "Bandstropp",
      beskrivning: "",
    },
    {
      id: "3.6",
      parent: "3",
      namn: "Hussling",
      beskrivning: "",
    },
    {
      id: "3.7",
      parent: "3",
      namn: "Båtlyftsstropp",
      beskrivning: "",
    },
    {
      id: "3.8",
      parent: "3",
      namn: "Lyftredskap",
      beskrivning: "",
    },
    {
      id: "4",
      namn: "Övriga lyftredskap och komponenter",
      beskrivning: "",
    },
    {
      id: "4.1",
      parent: "4",
      namn: "Lyftok",
      beskrivning: "",
    },
    {
      id: "4.2",
      parent: "4",
      namn: "Trumlyft",
      beskrivning: "",
    },
    {
      id: "4.3",
      parent: "4",
      namn: "Lyftblock",
      beskrivning: "",
    },
    {
      id: "4.3.1",
      parent: "4.3",
      namn: "Spaklyftblock",
      beskrivning: "",
    },
    {
      id: "4.3.2",
      parent: "4.3",
      namn: "Kedjeblock",
      beskrivning: "",
    },
    {
      id: "4.4",
      parent: "4",
      namn: "Schackel",
      beskrivning: "",
    },
    {
      id: "4.4.1",
      parent: "4.4",
      namn: "US-schackel",
      beskrivning: "",
    },
    {
      id: "4.4.1.1",
      parent: "4.4.1",
      namn: "Rak",
      beskrivning: "",
    },
    {
      id: "4.4.1.2",
      parent: "4.4.1",
      namn: "Lyr",
      beskrivning: "",
    },
    {
      id: "4.4.2",
      parent: "4.4",
      namn: "Schackel klass 8",
      beskrivning: "",
    },
    {
      id: "4.5",
      parent: "4",
      namn: "Maskinlyftögla klass 8",
      beskrivning: "",
    },
    {
      id: "4.7",
      parent: "4",
      namn: "Vantskruv klass 8",
      beskrivning: "",
    },
    {
      id: "5",
      namn: "Lastsäkring",
      beskrivning: "",
    },
    {
      id: "5.1",
      parent: "5",
      namn: "Surrningar",
      beskrivning: "",
    },
    {
      id: "5.1.1",
      parent: "5.1",
      namn: "25mm",
      beskrivning: "",
    },
    {
      id: "5.1.2",
      parent: "5.1",
      namn: "35mm",
      beskrivning: "",
    },
    {
      id: "5.1.3",
      parent: "5.1",
      namn: "50mm",
      beskrivning: "",
    },
    {
      id: "5.1.4",
      parent: "5.1",
      namn: "75mm",
      beskrivning: "",
    },
    {
      id: "5.2",
      parent: "5",
      namn: "Kamlås",
      beskrivning: "",
    },
    {
      id: "5.3",
      parent: "5",
      namn: "Godsstöttor",
      beskrivning: "",
    },
    {
      id: "5.4",
      parent: "5",
      namn: "Surrningskätting",
      beskrivning: "",
    },
    {
      id: "5.5",
      parent: "5",
      namn: "Lastspännare",
      beskrivning: "",
    },
    {
      id: "5.5.1",
      parent: "5.5",
      namn: "25mm",
      beskrivning: "",
    },
    {
      id: "5.5.2",
      parent: "5.5",
      namn: "35mm",
      beskrivning: "",
    },
    {
      id: "5.5.3",
      parent: "5.5",
      namn: "50mm",
      beskrivning: "",
    },
    {
      id: "5.5.4",
      parent: "5.5",
      namn: "75mm",
      beskrivning: "",
    },
    {
      id: "5.5.5",
      parent: "5.5",
      namn: "Kamlås",
      beskrivning: "",
    },	
    {
      id: "5.6",
      parent: "5",
      namn: "Surrningsbeslag",
      beskrivning: "",
    },
    {
      id: "5.6.1",
      parent: "5.6",
      namn: "25mm",
      beskrivning: "",
    },
    {
      id: "5.6.2",
      parent: "5.6",
      namn: "35mm",
      beskrivning: "",
    },
    {
      id: "5.6.3",
      parent: "5.6",
      namn: "50mm",
      beskrivning: "",
    },
    {
      id: "5.6.4",
      parent: "5.6",
      namn: "75mm",
      beskrivning: "",
    },
    {
      id: "5.7",
      parent: "5",
      namn: "Rostfritt",
      beskrivning: "Kontakta oss för mer info",
    },
  ],

  products: [
    {
      id: "11",
      categoryId: "1.1.1",
      namn: "6X7+FC",
    },
    {
      id: "12",
      categoryId: "1.1.1",
      namn: "6x12+7FC",
    },
    {
      id: "13",
      categoryId: "1.1.2",
      namn: "1x19",
    },
    {
      id: "14",
      categoryId: "1.1.1",
      namn: "6x24+7FC",
    },
    {
      id: "15",
      categoryId: "1.1.1",
      namn: "6x36+FC",
    },
    {
      id: "16",
      categoryId: "1.1.2",
      namn: "6x36+IWRC",
    },
    {
      id: "17",
      categoryId: "1.1.2",
      namn: "7x7",
    },
    {
      id: "18",
      categoryId: "1.1.1",
      namn: "6x19+FC",
    },
    {
      id: "19",
      categoryId: "1.1.2",
      namn: "7x19",
    },
    {
      id: "110",
      categoryId: "1.1.3",
      namn: "35x7",
    },
    {
      id: "111",
      categoryId: "1.1.3",
      namn: "19x7",
    },
    {
      id: "112",
      categoryId: "1.3.1",
      namn: "6x7+FC/PVC",
    },
    {
      id: "113",
      categoryId: "1.3.1",
      namn: "7x7/PVC",
    },
    {
      id: "114",
      categoryId: "1.3.1",
      namn: "1x19/PVC",
    },
    {
      id: "115",
      categoryId: "1.1.1",
      namn: "6x36+IWRC",
    },
    {
      id: "116",
      categoryId: "1.3.2",
      namn: "7x7/PVC",
    },
    {
      id: "117",
      categoryId: "1.3.2",
      namn: "1x19/PVC",
    },
    {
      id: "118",
      categoryId: "1.2",
      namn: "7x7",
    },
    {
      id: "119",
      categoryId: "1.2",
      namn: "1x19",
    },
    {
      id: "120",
      categoryId: "1.2",
      namn: "7x19",
    },
    {
      id: "121",
      categoryId: "1.4.1.1",
      namn: "Bygellås Galv",
    },
    {
      id: "122",
      categoryId: "1.4.1.1",
      namn: "Bygellås Rostfri",
    },
    {
      id: "123",
      categoryId: "1.4.1.2",
      namn: "Låsbleck Galv - Duplex",
    },
    {
      id: "124",
      categoryId: "1.4.1.2",
      namn: "Låsbleck Rostfri - Duplex",
    },
    {
      id: "125",
      categoryId: "1.4.1.3",
      namn: "Backlås Aseke",
    },
    {
      id: "126",
      categoryId: "1.4.1.3",
      namn: "Backlås Irongrip",
    },
    {
      id: "127",
      categoryId: "1.4.1.4",
      namn: "Presslås Alu",
    },
    {
      id: "128",
      categoryId: "1.4.1.4",
      namn: "Presslås CU",
    },
    {
      id: "129",
      categoryId: "1.4.2",
      namn: "Kaus Galv",
    },
    {
      id: "130",
      categoryId: "1.4.2",
      namn: "Kaus Rostfri",
    },
    {
      id: "131",
      categoryId: "1.4.3",
      namn: "Vantskruv Galv",
    },
    {
      id: "132",
      categoryId: "1.4.3",
      namn: "Vantskruv Rostfri",
    },
    {
      id: "133",
      categoryId: "1.4.3",
      namn: "Vantskruv Klass 8",
    },
    {
      id: "134",
      categoryId: "1.5",
      namn: "1-Part",
    },
    {
      id: "135",
      categoryId: "1.5",
      namn: "2-Part",
    },
    {
      id: "136",
      categoryId: "1.5",
      namn: "3-Part",
    },
    {
      id: "137",
      categoryId: "1.5",
      namn: "4-Part",
    },
    {
      id: "138",
      categoryId: "1.1.4",
      namn: "Svart stållina 7x19",
    },
    {
      id: "139",
      categoryId: "1.6",
      namn: "Stålinerundsling",
    },
    {
      id: "140",
      categoryId: "4.7",
      namn: "Vantskruv Klass 8",
    },
    {
      id: "271",
      categoryId: "2.1.1",
      namn: "1-Part",
    },
    {
      id: "272",
      categoryId: "2.1.1",
      namn: "2-Part",
    },
    {
      id: "273",
      categoryId: "2.1.1",
      namn: "3-Part",
    },
    {
      id: "274",
      categoryId: "2.1.1",
      namn: "4-Part",
    },
    {
      id: "22",
      categoryId: "2.1.2",
      namn: "Kätting",
    },
    {
      id: "241",
      categoryId: "2.2.1",
      namn: "1-Part",
    },
    {
      id: "242",
      categoryId: "2.2.1",
      namn: "2-Part",
    },
    {
      id: "243",
      categoryId: "2.2.1",
      namn: "3-Part",
    },
    {
      id: "244",
      categoryId: "2.2.1",
      namn: "4-Part",
    },
    {
      id: "250",
      categoryId: "2.2.3",
      namn: "Toppögla MF",
    },
    {
      id: "251",
      categoryId: "2.2.3",
      namn: "Toppögla MT",
    },
    {
      id: "252",
      categoryId: "2.2.3",
      namn: "Toppögla MG",
    },
    {
      id: "253",
      categoryId: "2.2.3",
      namn: "Toppögla MGD",
    },
    {
      id: "254",
      categoryId: "2.2.3",
      namn: "Kopplingslänk G ",
    },
    {
      id: "255",
      categoryId: "2.2.3",
      namn: "Kopplingslänk CG",
    },
    {
      id: "256",
      categoryId: "2.2.3",
      namn: "Kopplingslänk CGD",
    },
    {
      id: "257",
      categoryId: "2.2.3",
      namn: "Förkortningskrok OG",
    },
    {
      id: "258",
      categoryId: "2.2.3",
      namn: "Förkortningskrok GG",
    },
    {
      id: "259",
      categoryId: "2.2.3",
      namn: "Förkortningskrok GG med låspinne",
    },
    {
      id: "260",
      categoryId: "2.2.3",
      namn: "förkortningskrok - Midgrab",
    },
    {
      id: "261",
      categoryId: "2.2.3",
      namn: "Lastkrok EK",
    },
    {
      id: "262",
      categoryId: "2.2.3",
      namn: "Lastkrok EKN",
    },
    {
      id: "263",
      categoryId: "2.2.3",
      namn: "Säkerhetskrok OBK",
    },
    {
      id: "264",
      categoryId: "2.2.3",
      namn: "Säkerhetskrok GBK",
    },
    {
      id: "265",
      categoryId: "2.2.3",
      namn: "Lastkrok OKE",
    },
    {
      id: "266",
      categoryId: "2.2.3",
      namn: "förkortningskrok - Midgrab",
    },
    {
      id: "267",
      categoryId: "2.2.3",
      namn: "Lastkrok EK",
    },
    {
      id: "268",
      categoryId: "2.2.3",
      namn: "Lastkrok EKN",
    },
    {
      id: "269",
      categoryId: "2.2.3",
      namn: "Säkerhetskrok OBK",
    },
    {
      id: "270",
      categoryId: "2.2.3",
      namn: "Kättingspännare för lyft",
    },
    {
      id: "25",
      categoryId: "2.2.2",
      namn: "Kätting",
    },
    {
      id: "27",
      categoryId: "2.3.1.1",
      namn: "Kortlänkad",
    },
    {
      id: "28",
      categoryId: "2.3.1.1",
      namn: "Halvlånglänkad",
    },
    {
      id: "29",
      categoryId: "2.3.1.1",
      namn: "Långlänkad",
    },
    {
      id: "210",
      categoryId: "2.3.1.2",
      namn: "Kortlänkad",
    },
    {
      id: "211",
      categoryId: "2.3.1.2",
      namn: "Långlänkad",
    },
    {
      id: "212",
      categoryId: "2.4",
      namn: "Plastkätting",
    },
    {
      id: "213",
      categoryId: "2.3.5",
      namn: "Galvad",
    },
    {
      id: "214",
      categoryId: "2.3.5",
      namn: "Rostfri",
    },
    {
      id: "215",
      categoryId: "2.3.4.1",
      namn: "Mutter",
    },
    {
      id: "216",
      categoryId: "2.3.4.1",
      namn: "Tapp",
    },
    {
      id: "217",
      categoryId: "2.3.4.2",
      namn: "Mutter",
    },
    {
      id: "218",
      categoryId: "2.3.4.2",
      namn: "Tapp",
    },
    {
      id: "219",
      categoryId: "2.3.3.1",
      namn: "Rak",
    },
    {
      id: "220",
      categoryId: "2.3.3.1",
      namn: "Lyr",
    },
    {
      id: "221",
      categoryId: "2.3.3.2",
      namn: "Rak",
    },
    {
      id: "222",
      categoryId: "2.3.3.2",
      namn: "Lyr",
    },
    {
      id: "223",
      categoryId: "2.3.3.2",
      namn: "Vriden",
    },
    {
      id: "224",
      categoryId: "2.1.3",
      namn: "Säkerhetskrok ögla",
    },
    {
      id: "225",
      categoryId: "2.1.3",
      namn: "Säkerhetskrok gaffel",
    },
    {
      id: "226",
      categoryId: "2.1.3",
      namn: "Svivlande säkerhetskrok",
    },
    {
      id: "227",
      categoryId: "2.1.3",
      namn: "Spärrkrok ögla",
    },
    {
      id: "228",
      categoryId: "2.1.3",
      namn: "Spärrkrok gaffel",
    },
    {
      id: "229",
      categoryId: "2.1.3",
      namn: "Svivlande spärrkrok",
    },
    {
      id: "230",
      categoryId: "2.1.3",
      namn: "Kopplingslänk",
    },
    {
      id: "231",
      categoryId: "2.1.3",
      namn: "Rundslingskoppling",
    },
    {
      id: "232",
      categoryId: "2.1.3",
      namn: "Förkortningskrok Ögla",
    },
    {
      id: "232",
      categoryId: "2.1.3",
      namn: "Förkortningskrok gaffel",
    },
    {
      id: "233",
      categoryId: "2.1.3",
      namn: "Gjuterikrok",
    },
    {
      id: "234",
      categoryId: "2.1.3",
      namn: "Rundslingskrok",
    },
    {
      id: "235",
      categoryId: "2.1.3",
      namn: "Toppögla 1/2-part",
    },
    {
      id: "236",
      categoryId: "2.1.3",
      namn: "Toppögla 3/4-part",
    },
    {
      id: "237",
      categoryId: "2.1.3",
      namn: "Grävmaskinskrok",
    },
    {
      id: "31",
      categoryId: "3.6",
      namn: "Hussling",
    },
    {
      id: "32",
      categoryId: "3.1",
      namn:
        "Rundsling - Vi säljer rundsling från 1 ton upp till 50 ton. Hör gärna av er för mer info",
    },
    {
      id: "33",
      categoryId: "3.2",
      namn:
        "Stållinerundsling - Vi säljer stållinerundsling - Soft steel. Hör gärna av er för mer info",
    },
    {
      id: "34",
      categoryId: "3.3",
      namn:
        "Bandsling - Vi säljer bandsling för olika WLL. Hör gärna av er för mer info",
    },
    {
      id: "35",
      categoryId: "3.4",
      namn:
        "Rundslingsstropp - Vi säljer rundslingsstropp från 1 ton upp till 50 ton. Hör gärna av er för mer info",
    },
    {
      id: "36",
      categoryId: "3.5",
      namn:
        "Bandstropp - Vi säljer bandstropp från 1 ton upp till 10 ton. Hör gärna av er för mer info",
    },
    {
      id: "37",
      categoryId: "3.7",
      namn:
        "Båtlyftstropp - Vi säljer Båtlyftstroppar av olika modeller och WLL. Hör gärna av er för mer info",
    },
    {
      id: "38",
      categoryId: "3.8",
      namn: "1-Part",
    },
    {
      id: "39",
      categoryId: "3.8",
      namn: "2-Part",
    },
    {
      id: "310",
      categoryId: "3.8",
      namn: "3-Part",
    },
    {
      id: "311",
      categoryId: "3.8",
      namn: "4-Part",
    },
    {
      id: "41",
      categoryId: "4.4.1.1",
      namn: "Mutter",
    },
    {
      id: "42",
      categoryId: "4.4.1.1",
      namn: "Pinne",
    },
    {
      id: "43",
      categoryId: "4.4.1.2",
      namn: "Mutter",
    },
    {
      id: "44",
      categoryId: "4.4.1.2",
      namn: "Pinne",
    },
    {
      id: "45",
      categoryId: "4.4.2",
      namn: "Rak",
    },
    {
      id: "46",
      categoryId: "4.4.2",
      namn: "Lyr",
    },
    {
      id: "47",
      categoryId: "4.5",
      namn: "Mutter",
    },
    {
      id: "48",
      categoryId: "4.5",
      namn: "Tapp",
    },
    {
      id: "49",
      categoryId: "4.2",
      namn: "Trumlyft 2ton(8mm), 3.15ton(10mm). 5.3ton(13mm)",
    },
     {
      id: "410",
      categoryId: "4.3.1",
      namn: "Spaklyftblock mini - 250/500kg",
    },
    {
      id: "411",
      categoryId: "4.3.1",
      namn: "Spaklyftblock pro - 800-9000kg",
    },
    {
      id: "412",
      categoryId: "4.3.2",
      namn: "Kedjeblock pro - 250-7500kg",
    },
    {
      id: "413",
      categoryId: "4.1",
      namn: "Lyftok - Säljer olika varianter av lyftok, kontakta oss för mer info",
    },
    {
      id: "51",
      categoryId: "5.1.3",
      namn: "Standardsurrning 0.5+9.5m",
    },
    {
      id: "52",
      categoryId: "5.1.3",
      namn: "Invändning surrning med krok",
    },
    {
      id: "53",
      categoryId: "5.1.3",
      namn: "Invändning surrning med E-fäste",
    },
    {
      id: "54",
      categoryId: "5.1.3",
      namn: "Kundanpassad surrning enligt önskemål",
    },
    {
      id: "55",
      categoryId: "5.1.2",
      namn: "Standardsurrning 0.5+5.5m",
    },
    {
      id: "56",
      categoryId: "5.1.2",
      namn: "Kundanpassad surrning enligt önskemål",
    },
    {
      id: "57",
      categoryId: "5.1.1",
      namn: "Standardsurrning 0.4+4.6m",
    },
    {
      id: "58",
      categoryId: "5.1.1",
      namn: "Ändlös surrning",
    },
    {
      id: "59",
      categoryId: "5.1.1",
      namn: "Kundanpassad surrning enligt önskemål",
    },
    {
      id: "510",
      categoryId: "5.1.4",
      namn: "75mm surrning i olika utföranden enligt kundens önskemål",
    },
    {
      id: "511",
      categoryId: "5.5.1",
      namn: "25mm-spännare",
    },
    {
      id: "512",
      categoryId: "5.5.1",
      namn: "25mm-spännare med brett handtag",
    },
    {
      id: "513",
      categoryId: "5.5.2",
      namn: "35mm-spännare",
    },
    {
      id: "514",
      categoryId: "5.5.2",
      namn: "35mm-spännare för inv. surrning",
    },
    {
      id: "515",
      categoryId: "5.5.2",
      namn: "Svart 35mm-spännare",
    },
    {
      id: "516",
      categoryId: "5.5.2",
      namn: "35mm-spännare utan transportspärr",
    },
    {
      id: "517",
      categoryId: "5.5.2",
      namn: "35mm-spännare med blått fingerhandtag",
    },
    {
      id: "518",
      categoryId: "5.5.2",
      namn: "35mm-spännare med aluminiumhandtag",
    },
    {
      id: "519",
      categoryId: "5.5.3",
      namn: "50mm-spännare",
    },
    {
      id: "520",
      categoryId: "5.5.3",
      namn: "50mm-spännare med långt handtag",
    },
    {
      id: "521",
      categoryId: "5.5.3",
      namn: "Svart 50mm-spännare",
    },
    {
      id: "522",
      categoryId: "5.5.3",
      namn: "50mm-spännare för inv. surrning",
    },
    {
      id: "523",
      categoryId: "5.5.3",
      namn: "50mm-spännare med blått fingerhandtag",
    },
    {
      id: "524",
      categoryId: "5.5.3",
      namn: "50mm-spännare Ergo",
    },
    {
      id: "525",
      categoryId: "5.5.3",
      namn: "50mm-spännare utan transportspärr",
    },
    {
      id: "526",
      categoryId: "5.5.3",
      namn: "50mm-spännare utan transportspärr - Kort",
    },
    {
      id: "527",
      categoryId: "5.5.3",
      namn: "50mm-spännare utan transportspärr - Kort/smalt",
    },	
    {
      id: "528",
      categoryId: "5.5.4",
      namn: "75mm-spännare",
    },
    {
      id: "529",
      categoryId: "5.6.1",
      namn: "25mm Dubbel trådkrok",
    },		
    {
      id: "530",
      categoryId: "5.6.1",
      namn: "25mm D-ring",
    },	
    {
      id: "531",
      categoryId: "5.6.1",
      namn: "Single-Stud",
    },	
    {
      id: "532",
      categoryId: "5.6.2",
      namn: "35mm dubbel trådkrok",
    },	
    {
      id: "533",
      categoryId: "5.6.2",
      namn: "Svart 35mm dubbel trådkrok",
    },	
    {
      id: "534",
      categoryId: "5.6.2",
      namn: "35mm dubbel trådkrok - Direktmonterad",
    },	
    {
      id: "535",
      categoryId: "5.6.2",
      namn: "35mm dubbel trådkrok - Med spärr",
    },	
    {
      id: "536",
      categoryId: "5.6.2",
      namn: "35mm enkel trådkrok",
    },	
    {
      id: "537",
      categoryId: "5.6.2",
      namn: "35mm D-ring",
    },	
    {
      id: "538",
      categoryId: "5.6.2",
      namn: "35mm O-ring",
    },	
    {
      id: "539",
      categoryId: "5.6.3",
      namn: "50mm dubbel trådkrok",
    },	
    {
      id: "540",
      categoryId: "5.6.3",
      namn: "Svart 50mm dubbel trådkrok",
    },	
    {
      id: "541",
      categoryId: "5.6.3",
      namn: "50mm dubbel trådkrok - Direktmonterad",
    },	
    {
      id: "542",
      categoryId: "5.6.3",
      namn: "50mm dubbel trådkrok - Med spärr",
    },	
    {
      id: "543",
      categoryId: "5.6.3",
      namn: "50mm dubbel trådkrok - Direktmonterad med spärr",
    },	
    {
      id: "544",
      categoryId: "5.6.3",
      namn: "50mm enkel trådkrok",
    },	
    {
      id: "545",
      categoryId: "5.6.3",
      namn: "50mm Kättingkrok",
    },	
    {
      id: "546",
      categoryId: "5.6.3",
      namn: "50mm krok för inv. surrning",
    },	
    {
      id: "547",
      categoryId: "5.6.3",
      namn: "50mm E-fäste för inv. surrning",
    },	
    {
      id: "548",
      categoryId: "5.6.3",
      namn: "50mm förstärkt triangel",
    },	
    {
      id: "549",
      categoryId: "5.6.3",
      namn: "50mm triangel",
    },	
    {
      id: "550",
      categoryId: "5.6.2",
      namn: "Dubbel-Stud",
    },	
    {
      id: "551",
      categoryId: "5.6.3",
      namn: "50mm Flat karbinkrok",
    },	
    {
      id: "552",
      categoryId: "5.6.3",
      namn: "50mm Vriden karbinkrok",
    },	
    {
      id: "553",
      categoryId: "5.6.3",
      namn: "50mm - Ekeri-beslag",
    },	
    {
      id: "554",
      categoryId: "5.6.3",
      namn: "50mm - Hook and keeper",
    },
    {
      id: "555",
      categoryId: "5.6.3",
      namn: "Quadro-Stud ",
    },
    {
      id: "556",
      categoryId: "5.6.4",
      namn: "75mm Dubbel trådkrok",
    },
    {
      id: "557",
      categoryId: "5.6.4",
      namn: "75mm Triangel",
    },
    {
      id: "558",
      categoryId: "5.6.4",
      namn: "75mm Demonterbar triangel",
    },
    {
      id: "559",
      categoryId: "5.5.5",
      namn: "25mm kamlåsspänne",
    },
    {
      id: "560",
      categoryId: "5.5.5",
      namn: "Svart 25mm kamlåsspänne",
    },
    {
      id: "561",
      categoryId: "5.5.5",
      namn: "35mm kamlåsspänne",
    },
    {
      id: "562",
      categoryId: "5.5.5",
      namn: "50mm kamlåsspänne",
    },
    {
      id: "563",
      categoryId: "5.2",
      namn: "25mm kamlås i olika längder",
    },
    {
      id: "564",
      categoryId: "5.4",
      namn: "Surrningskätting i olika utföranden",
    },
    {
      id: "565",
      categoryId: "5.3",
      namn: "Godsstötta - Ergo",
    },
    {
      id: "566",
      categoryId: "5.3",
      namn: "Godsstötta - Gas",
    },
  ]
};
