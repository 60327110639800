import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Products from "./Products";
import allData from "../../data/allData";
import csv from "csvtojson";

class ProductsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state =  {
      category: null,
      products: [],
      slug: null,
      modalOpen: false,
      parentCategories: []
    }
  }

  async updateData() {
    const slug = this.props.match.params.Id;

    let products = allData.products.filter((item) => {
      if(item.categoryId === slug.replace(/_/g, '.')) {
        return true;
      }
      return false;
    });

    let category = allData.categories.find(item => item.id === slug.replace(/_/g, '.'));

    let promises = products.map(async (item) => {
      item.showSpec = false;

      await fetch('src/data/specs/Prod_'+ item.id +'.csv').then(async function (response) {
        if(!response.ok) {
          return null;
        }

        let reader = response.body.getReader();
        let decoder = new TextDecoder('utf-8');

        let csvData = await reader.read().then(function (result) {
          return decoder.decode(result.value);
        });

        if(csvData) {
          await csv()
          .fromString(csvData.replace(/\n/g,' ').replace(/\/%/g, ''))
          .then((jsonObj) => {
            item.csvData = jsonObj;
            return true;
          });
        } else {
          item.csvData = null;
        }

        return 12345;
      }).catch(() => {
        item.csvData = null;
        return false;
      });

      //console.log("Iteration of item", item);
    });
    
    Promise.all(promises).then(() => {

      let parentCategories = this.getParentCat(category, []);

      this.setState({parentCategories, products, slug, category});
    });
  }

  getParentCat = function(cat, arr) {
    let parent;

    while (cat && cat.parent) {
      parent = allData.categories.find(item => {
        return (item.id == cat.parent);
      });

      if(parent) {
        arr.unshift(parent);
      } 

      cat = (parent && parent.parent) ? parent : {};
    }

    return arr;
  }

  showSpec = (id) => {
    let newProdList = [...this.state.products];
    
    newProdList.map((item) => {
      if(item.id === id) {
        console.log("found");
        item.showSpec = true;
        return;
      }
    });
    this.setState({products: newProdList});
  }

  hideSpec = (id) => {
    let newProdList = [...this.state.products];
    
    newProdList.map((item) => {
      if(item.id === id) {
        console.log("found");
        item.showSpec = false;
        return;
      }
    });
    this.setState({products: newProdList});
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateData();
  }

  componentDidUpdate() {
    let _slug = this.props.match.params.Id;
    if(this.state.slug != _slug) {
      this.updateData();
    }
  }

  render() {
    return (
      <Products
        parentCategories={this.state.parentCategories}
        products={this.state.products}
        category={this.state.category}
        showSpec={this.showSpec}
        hideSpec={this.hideSpec}
      />
    );
  }
}

ProductsContainer.propTypes = {
  match: PropTypes.object
};

function mapStateToProps() {
  return {}  
}

const mapDispatchToProps = {

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsContainer);