import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ContactForm = ({ handleNameChange, handleCompanyChange, handleMessageChange, handleMailSend, handleEmailChange, form, product }) => (
  <div id="contact" className="block--gray contact">
      <i className="blockIcon fas fa-envelope"></i>
      <div className="blockContent">
        {(form.sent) ? (
          <>
            <h2>Din fråga har skickats!</h2>
            Vi har tagit emot din fråga och kommer att besvara den så snart vi kan!<br /><br />
            <strong>Vi har tagit emot följande information:</strong><br />
            Namn: {form.name}<br />
            Företag: {form.company}<br />
            Epostadress: {form.email}<br />
            Fråga: {form.message}<br />
          </>
        ) : (<>
        <>
            {(product) ? (<h2>Har du frågor om produkten?</h2>) : (<h2>Vill du bli kontaktad?</h2>)}
            Om du vill bli kontaktad av oss angående våra produkter och tjänster,
            vänligen lämna dina kontaktuppgifter och en kort beskrivning nedan, så hör
            vi av oss så snart vi kan.
            <input 
              type="text" 
              value={form.name} 
              onChange={handleNameChange} 
              placeholder="Namn" />

            <input 
              type="text" 
              value={form.company} 
              onChange={handleCompanyChange} 
              placeholder="Företag" />

            <input 
              type="text" 
              value={form.email} 
              onChange={handleEmailChange} 
              placeholder="E-postadress" />

            <textarea 
              value={form.message}
              onChange={handleMessageChange}
              placeholder="Beskrivning/Fråga" />
              
            <button onClick={handleMailSend}>Skicka</button>
            <button>Rensa</button>
          </>
        </>) }
        <div style={{ clear: "both" }} />
      </div>
    </div>
);

ContactForm.propTypes = {
  subCategories: PropTypes.array,
  category: PropTypes.object
};

export default ContactForm;
