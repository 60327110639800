import React from "react";
import PropTypes from "prop-types";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Link } from "react-router-dom";
import ContactForm from "./../contactForm/ContactFormContainer";

const Products = ({ parentCategories = [], products, category, showSpec, hideSpec }) => (
  <div className="Products">

    <div className="breadcrumbs">
      <Link to="/ourproducts">Våra produkter</Link>
      {
        parentCategories.map(
          parent => {
            return(
              <>
                <strong> / </strong>
                <Link key={'P' + parent.id} id={'P' + parent.id} to={'/category' + parent.id.replace(/\./g, "_")}>
                  {parent.namn}
                </Link> 
              </>
            );
          }
        )
      }
      <strong> / </strong>{category ? category.namn : null}
    </div>

    <div className="block">
      <h1>{category ? category.namn : '' }</h1>
      <div className="blockContent flexCenter">
        {products.map(prod => {
          return (
            <div key={"prod_"+ prod.id} className="productContent">
              <img src={"/src/resources/products/Prod_"+ prod.id +".jpg"} alt="" onError={(e)=>{e.target.onerror = null; e.target.src="/src/resources/products/noimg.jpg"}} style={{ display: 'block', margin: '0 auto', maxWidth: '100%' }} />
              <h2 style={{
                display: 'inline-block',
                marginTop: '5px',
                lineHeight: '40px',
                marginBottom: '0'
              }}>{prod.namn}</h2>
              {prod.csvData !== null && prod.csvData !== undefined ? (<button onClick={() => showSpec(prod.id)} className="readMore">Produktspecifikation</button>): (null)}


              

              <Modal open={prod.showSpec} onClose={() => hideSpec(prod.id)} center classNames={{
                modal: 'customModal'
              }}>
                {prod.csvData !== null && prod.csvData !== undefined ? (
                  <>
                    {prod.csvData.map(row => {
                      console.log(row);
                      return (
                        <>
                          {Object.keys(row).map(header => (
                            <>
                              {header + ": " + row[header]}
                              <br />
                            </>
                          ))}
                          <br />
                          <br />
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    NO
                    <br />
                  </>
                )}
              </Modal>
            </div>
          );
        })}
      </div>
    </div>

    <ContactForm product={true} />
  </div>
);

Products.propTypes = {
  products: PropTypes.array,
  category: PropTypes.object,
  showSpec: PropTypes.func,
  hideSpec: PropTypes.func,
};

export default Products;
