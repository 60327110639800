import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ContactForm from "./ContactForm";
import axios from "axios";

class ContactFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state =  {
      product: this.props.product ? true : false,
      form: {
        name: '',
        company: '',
        message: '',
        email: '',
        sent: false
      }
    }
  }


  componentDidMount() {

  }

  componentDidUpdate() {

  }

  nameChange = (event) => {
    this.setState({ form: {...this.state.form, name: event.target.value} });
  }

  companyChange = (event) => {
    this.setState({ form: {...this.state.form, company: event.target.value} });
  }

  emailChange = (event) => {
    this.setState({ form: {...this.state.form, email: event.target.value} });
  }

  messageChange = (event) => {
    this.setState({ form: {...this.state.form, message: event.target.value} });
  }

  mailSend = () => {
    let params = new URLSearchParams();

    if(this.state.form.name.length < 1) {
      alert("Du måste ange ett namn");
    } else if(this.state.form.company.length < 1) {
      alert("Du måste ange ett företagsnamn");
    } else if(!this.validateEmail(this.state.form.email)) {
      alert("Du måste ange en giltig epostadress");
    } else if(this.state.form.message.length < 1) {
      alert("Du måste ange ett meddelande");
    } else {

      params.append('from', this.state.form.email);
      params.append('message', this.state.form.message);
      params.append('company', this.state.form.company);
      params.append('name', this.state.form.name);
      params.append('url', window.location.href);
  
      axios.post("/mail.php", params)
        .then(res => {
          console.log(res);
        });
        
      this.setState({ form: {...this.state.form, sent: true} });
    }
  }

  validateEmail = (mail) => {
    if(mail.length < 5) {
      return false;
    }

    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
        return true;
    }

    return false;
  }

  render() {
    return (
      <ContactForm 
        handleNameChange={this.nameChange}
        handleCompanyChange={this.companyChange}
        handleMessageChange={this.messageChange}
        handleMailSend={this.mailSend}
        handleEmailChange={this.emailChange}
        form={this.state.form}
        product={this.state.product}
      />
    );
  }
}

ContactFormContainer.propTypes = {
};

function mapStateToProps() {
  return {}  
}

const mapDispatchToProps = {

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactFormContainer);