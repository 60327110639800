import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ContactForm from "./../contactForm/ContactFormContainer";

const Category = ({ parentCategories, subCategories, category }) => (
  <div className="Category">
    <div className="breadcrumbs">
      <Link to="/ourproducts">Våra produkter</Link>
      {
        parentCategories.map(
          parent => {
            return(
              <React.Fragment key={'P' + parent.id}>
                <strong> / </strong>
                <Link id={'P' + parent.id} to={'/category' + parent.id.replace(/\./g, "_")}>
                  {parent.namn}
                </Link> 
              </React.Fragment>
            );
          }
        )
      }
      <strong> / </strong>{category ? category.namn : null}
    </div>

    <div className="block">
    <h1>{category ? category.namn : null}</h1>
      <div className="blockContent flex">
        {subCategories.map(
          subCat => {
            return (
              <Link className="subCat" key={subCat.id} id={subCat.id} to={subCat.hasProd ? "/products" + subCat.id.replace(/\./g, '_') : "/category" + subCat.id.replace(/\./g, '_')}>
                  <div
                    className="subCat__img"
                    style={{
                      backgroundImage: "url(./src/resources/categories/Cat_"+subCat.id+".jpg)"
                    }}
                  >
                  </div>
          <div className="subCat__info">{subCat.namn}</div>
              </Link>
            );
          }
        )}
      </div>
    </div>

    <ContactForm />
  </div>
);

Category.propTypes = {
  subCategories: PropTypes.array,
  category: PropTypes.object
};

export default Category;
