import React from "react";
import { render } from "react-dom";
import "./index.css";
import configureStore from "./redux/configureStore";
import { Provider as ReduxProvider } from "react-redux";
import App from "./components/App";

const store = configureStore();

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('service-worker.js')
        .then((reg) => {
          console.log('Service worker registered.', reg);
        });
  });
} else {
  console.log('SW fail');
}

render(
  <ReduxProvider store={store}>
      <App />
  </ReduxProvider>,
  document.getElementById("app")
);
