import React from "react";
import ContactForm from "./../contactForm/ContactFormContainer";

const ContactPage = () => (
  <div>
       <div
      style={{
        backgroundImage: "url(./src/resources/images/contactBanner.jpg)",
        borderBottom: '1px solid #000000'
      }}
      className="bannerImage"
    />

    <div className="block">
      <h2>Kontakta oss</h2>
      <div className="blockContent">
        Nedan finner du aktuell information för att komma i kontakt med oss på LH-produkter.
        Du kan även använda formuläret i botten på sidan för att göra en orderförfrågan eller bli kontaktad av oss angående andra frågetecken du har.
        <p>
          <strong>Epost:</strong> <a href="mailto:info@lh-produkter.se">info@lh-produkter.se</a><br />
          <strong>Telefon:</strong> <a href="tel:013-161360">013-161360</a><br />
          <strong>Mobiltelefon:</strong> <a href="tel:0707-358465">0707-358465</a><br />
        </p>
      </div>
    </div>

    <ContactForm />
  </div>
);

export default ContactPage;
