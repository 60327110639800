import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "./../contactForm/ContactFormContainer";

const CasesView = () => (
  <div>
       <div
      style={{
        backgroundImage: "url(./src/resources/images/casesBanner.jpg)",
        borderBottom: '1px solid #000000'
      }}
      className="bannerImage orientationBottom"
    />

    <div className="block">
      <h2>Våra kundområden</h2>
      <div className="blockContent">
        <p>Nedan följer några av LH-produkters vanligaste kundområden. Våra produkter håller högsta kvalitet och kommer från välkända leverantörer med många års erfarenhet. 
        Med ett brett utbud av produkter och kompetens kan vi erbjuda våra kunder helhetslösningar för alla tänkbara lyft- och lastbehov. <Link to={"/contact"}>Kontakta oss</Link> gärna för råd och rekommendationer.</p>

          <br /><hr/><br />

        <h3>Bygg och Anläggning</h3>
        <p>
          LH-produkter erbjuder våra kunder inom bygg och anläggning redskap för att få er verksamhet att fungera.  
          Vi har ett stort utbud av produkter till konstruktion- och byggföretag och i vårt sortiment hittar ni bland annat stållinor, lyftutrustning och rundsling/rundstroppar. 
        </p>

        <h3>Energi</h3>
        <p>LH-produkter erbjuder våra kunder inom energi-industrin redskap för att få er verksamhet att fungera.  
          Vi har ett stort utbud av produkter till energiföretag.</p>

        <h3>Försvarsmakten</h3>
        <p>LH-produkter erbjuder våra kunder inom försvarsmakten redskap för att få er verksamhet att fungera.  
          Vi har ett stort utbud av produkter för era behov och i vårt sortiment hittar ni bland annat stållinor, lyftutrustning och rundsling/rundstroppar. </p>

        <h3>Hissar</h3>
        <p>LH-produkter erbjuder våra kunder inom hisstillverkning redskap för att få er verksamhet att fungera.  
        Vi har ett stort utbud av produkter till hisstillverkningsföretag och i vårt sortiment hittar ni bland annat hisslinor och lyftutrustning.</p>
        <h3>Produktion</h3>
        <p>LH-produkter erbjuder våra kunder inom produktionsindustrin redskap för att få er verksamhet att fungera. 
          Vi har ett stort utbud av produkter till produktionsföretag och i vårt sortiment hittar ni bland annat stållinor, lyftutrustning och rundsling/rundstroppar. </p>

        <h3>Sjöfart</h3>
        <p>LH-produkter erbjuder våra kunder inom hamnindustrin redskap för att få er verksamhet att fungera.  
          Vi har ett stort utbud av produkter för verksamhet inom sjöfart och i vårt sortiment hittar ni bland annat stållinor, lyftutrustning och rundsling/rundstroppar anpassat för hamnindustrin. </p>

        <h3>Stålindustri</h3>
        <p>LH-produkter erbjuder våra kunder inom stålindustrin redskap för att få er verksamhet att fungera.  
          Vi har ett stort utbud av produkter till industriföretag och i vårt sortiment hittar ni bland annat stållinor, lyftutrustning och rundsling/rundstroppar. </p>

        <h3>Åkerier</h3>
        <p>LH-produkter erbjuder våra kunder inom åkeribranschen redskap för att säkra last i rörelse.  
          Vi har ett stort utbud av produkter till åkeriföretag och i vårt sortiment hittar ni bland annat surrningsprodukter, lyftutrustning och rundsling/rundstropp samt bärgningsstropp.</p>
      </div>
    </div>

    <ContactForm />
  </div>
);

export default CasesView;
